<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Transfer Payment</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          @submit.prevent="transferPayment()"
        >
          <v-card-text>
            <!-- Read-Only Information Section -->
            <v-card class="mb-4">
              <v-card-title class="text-subtitle-1">
                Payment Details
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <v-list dense>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>{{ icons.mdiFileDocument }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Transaction Reference</v-list-item-title>
                          <v-list-item-subtitle class="text-primary">
                            {{ transactionReference }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>{{ icons.mdiCash }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Amount</v-list-item-title>
                          <v-list-item-subtitle class="text-success">
                            {{ amount | formatCurrency }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon>{{ icons.mdiBank }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>From Account</v-list-item-title>
                          <v-list-item-subtitle class="text-secondary">
                            {{ fromAccount }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <!-- Editable Fields Section -->
            <v-card class="mb-4">
              <v-card-title class="text-subtitle-1">
                Transfer To
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    class="mb-4"
                  >
                    <CustomerAutocomplete
                      :form-errors="form.errors"
                      :show-dialog="showDialog"
                      :label="'To'"
                      @update="updateCustomerId"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <!-- Action Buttons -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mdiFileDocument, mdiCash, mdiBank } from '@mdi/js'
import CustomerAutocomplete from '@/components/partials/CustomerSearchAutocomplete.vue'

export default {
  components: {
    CustomerAutocomplete,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    form: new Form({
      target_customer_id: '',
    }),
    icons: {
      mdiFileDocument,
      mdiCash,
      mdiBank,
    },
    action: 'Transfer',
  }),
  computed: {
    transactionReference() {
      return this.payment.reference
    },
    amount() {
      return this.payment.amount
    },
    fromAccount() {
      const customer = this.payment.customer || {}

      return `${customer.user?.name || 'Unknown'} - ${customer.account_number || 'N/A'}`
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    updateCustomerId(newCustomerId) {
      this.form.target_customer_id = newCustomerId
    },
    transferPayment() {
      this.form
        .post(`/payments/${this.payment.id}/transfer`)
        .then(() => {
          this.$toast.success(
            'Payment successfully transferred.',
          )
          this.$emit('close')
          this.$emit('payment-transferred')
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        })
    },
  },
}
</script>

<style lang="scss">
.v-menu__content:not(.list-style) .v-list-item {
  min-height: 48px !important;
  height: unset !important;
}
</style>
